import React, { useState, useRef, useEffect } from "react";
import {
	Avatar,
	CardActionArea,
	Container,
	Grid,
	Select,
	MenuItem,
	Card,
	CardHeader,
	CardContent,
	Typography,
	OutlinedInput,
	Autocomplete,
	TextField,
	Skeleton,
	Box,
	Slide
} from "@mui/material";
import AthleteProfileCard from "../components/AthleteProfileCard";
import ChallengesLeaderboard from "../components/ChallengesLeaderboard";

const LeaderboardPage = (props) => {
	const ref = useRef(null);
	const [topic, setTopic] = useState(props.topic);
	const [athlete, setAthlete] = useState(null);
	const [matches, setMatches] = useState([]);
	const [loading, setLoading] = useState(false);
	const [lastId, setLastId] = useState(null);
	const [searched, setSearched] = useState("");
	const [chosen, setChosen] = useState(null);
	const [athleteList, setAthleteList] = useState(props.topAthletes[topic]);
	const [category, setCategory] = useState(null);

	const handleChangeTopic = (event) => {
		setTopic(event.target.value);
		if (event.target.value === "Categories") {
			setCategory(props.topAthletes[event.target.value].categories[0].id);
			setAthleteList(props.topAthletes[event.target.value].athletes.filter(at => at.catid === props.topAthletes[event.target.value].categories[0].id));
		} else {
			setCategory(null);
			setAthleteList(props.topAthletes[event.target.value]);
		}
	};

	const handleChangeCategory = (event) => {
		setCategory(event.target.value);
		setAthleteList(props.topAthletes[topic].athletes.filter(at => at.catid === event.target.value));
	};

	const retrieveAvatar = (id) => {
		return props.avatars.get(id);
	};

	const formatAmount = (value, dimension) => {
		let response = "";
		let hasUnit = ["Trophies", "Activities"].indexOf(dimension) < 0;
		let unitShort = hasUnit
			? props.dimensions.find((dim) => dim.name === dimension).unitShort
			: "";
		if (dimension === "Duration") {
			let mins = "0" + parseInt((value * 60) % 60);
			response =
				parseInt(value).toLocaleString("en-US") +
				" " +
				unitShort +
				" " +
				mins.substring(mins.length - 2) +
				" min";
		} else {
			response = Math.round(value).toLocaleString("en-US") + " " + unitShort;
		}
		return response;
	};

	const displayCard = (id) => {
		if (id && id !== lastId) {
			setMatches([]);
			setLoading(true);
			fetch(
				"api/getProfile/" + id + "?utcOffset=" + new Date().getTimezoneOffset(),
				{ accept: "application/json" }
			)
				.then((resp) => resp.json())
				.then((data) => {
					setAthlete(data);
					setLoading(false);
					setLastId(id);
				});
		}
	};

	const closeProfileCard = () => {
		setTimeout(() => window.scrollTo({ top: 0, behavior: "smooth" }), 100);
		setMatches([]);
		setSearched("");
		setAthlete(null);
		setLastId(null);
		setChosen(null);
	};

	const handleSearchTermChange = (event) => {
		if (event && typeof event.target.value !== "undefined") {
			let newValue = event.target.value;
			setSearched(newValue);
			// Only load when we have 3 characters
			if (newValue.length === 3) {
				fetch("api/getAthletes?searchTerm=" + newValue, {
					accept: "application/json",
				})
					.then((resp) => resp.json())
					.then((data) => {
						setMatches(data);
					});
			} else {
				// If less than 3 characters and there is a previous list, remove it
				if (newValue.length < 3 && matches.length > 0) {
					setMatches([]);
					setChosen(null);
				}
			}
		}
	};

	const medalColors = ["#FFD700", "#eeeeee", "#b08d57"];

	useEffect(() => {
		if (
			loading &&
			document
				.getElementsByClassName("animate-content-card-up")[1]
				.getBoundingClientRect().top > window.innerHeight
		) {
			window.scrollTo({ top: document.body.scrollHeight, behavior: "smooth" });
		}
	}, [ref, loading]);

	return (
		<Container sx={{ my: 5 }} maxWidth="lg">
			<Grid container spacing={2}>
				<Grid item xs={12} md={6}>
					<Card className="animate-content-card-up">
						<CardHeader
							action={
								<Select
									value={topic}
									onChange={handleChangeTopic}
									size="small"
									variant="filled"
									input={<OutlinedInput />}
								>
									{Object.keys(props.topAthletes).map((key, i) => (
										<MenuItem key={i} value={key}>
											{key}
										</MenuItem>
									))}
								</Select>
							}
							title={
								<Typography component="h2" fontWeight="bold">
									Top Athletes
								</Typography>
							}
						/>
						{topic !== "Challenges" ?
							(<>
								{(topic === "Categories") && 
								<CardContent>
									<Typography variant="caption" display="block">
										Select a sport category 
									</Typography>
									<Select
										value={category}
										onChange={handleChangeCategory}
										variant="standard"
										size="small"
										sx={{ width: "100%" }}
										MenuProps={{ PaperProps: { style: { maxHeight: "40%" } } }}
									>
										{props.topAthletes[topic].categories.map((cat, i) => (
											<MenuItem key={i} value={cat.id}>{cat.name}</MenuItem>
										))}
									</Select>
								</CardContent>}
								{athleteList.length > 0 ?
								(
									<CardContent>
										{athleteList.map((row, index) => (
											<Slide 
												key={index}
												direction="right" 
												in
												timeout={{
													enter: index * 100 + 500,
												}}
											>
											<Card 
												key={index} 
												sx={{ 
													mb: 1,
													border: "1px solid" + index < 3 && medalColors[index],
													backgroundColor: index < 3 && medalColors[index] + 50,
												}}
											>
												<CardActionArea
													onClick={() => row.id > -1 && displayCard(row.id)}
												>
													<CardContent
														sx={{
															p: 1,
															"&:last-child": { pb: 1 },
															display: "flex",
															alignItems: "center",
															justifyContent: "space-between",
														}}
													>
														<Box
															style={{
																display: "flex",
																flexDirection: "row",
																alignItems: "center",
																flex: "0 1 auto",
																overflow: "hidden",
																textOverflow: "ellipsis",
															}}
														>
															<Avatar
																alt={row.athlete}
																src={retrieveAvatar(row.id)}
																sx={{ mr: 2, width: 32, height: 32 }}
															/>
															<Typography variant="body1" fontWeight="600" noWrap>
																{row.athlete}
															</Typography>
														</Box>
														<Typography
															variant="body2"
															fontWeight="600"
															sx={{ flex: "0 0 auto" }}
														>
															{formatAmount(
																row.amount, 
																topic !== "Categories" 
																	? topic 
																	: props.dimensions.find(dim => dim.id === props.topAthletes[topic].categories.find(cat => cat.id === category).dimensionId).name
															)}
														</Typography>
													</CardContent>
												</CardActionArea>
											</Card>
											</Slide>
										))}
									</CardContent>
								) : (
									<Typography align='center' sx={{ mt: 1, mb: 1, fontSize: '16px' }}>
										No leaderboard data available yet.
									</Typography>
								)
								}
							</>
							) :
							(
								<ChallengesLeaderboard
									allChallenges={athleteList}
									default={athleteList.filter((ch) => ch.mn)[0].id}
									dimensions={props.dimensions}
									displayCard={displayCard}
								/>
							)
						}
					</Card>
				</Grid>
				<Grid item xs={12} md={6}>
					<Card className="animate-content-card-up">
						<CardHeader
							title={
								<Typography component="h2" fontWeight="bold">
									Search Athletes
								</Typography>
							}
						/>
						<CardContent>
							<Autocomplete
								fullWidth
								autoHighlight
								disableClearable
								noOptionsText="No matches"
								sx={{ mb: 2.75 }}
								options={matches}
								getOptionLabel={(option) => {
									let result = "";
									if (searched.length > 2) {
										if (
											option.stravaName
												.toUpperCase()
												.includes(searched.toUpperCase())
										) {
											result = option.stravaName;
										} else {
											if (
												option.deloitteName
													.toUpperCase()
													.includes(searched.toUpperCase())
											) {
												result = option.deloitteName;
											} else {
												result = option.email;
											}
										}
									}
									return result;
								}}
								renderOption={(props, option) => (
									<Box component="li" {...props}>
										<span>
											<span
												style={{
													display: "flex",
													flexDirection: "row",
													alignItems: "center",
													justifyContent: "left",
												}}
											>
												<img
													src="img/Strava_Circle.png"
													alt="Strava logo"
													width="16px"
													height="16px"
												/>
												<Typography
													variant="caption"
													sx={{ fontWeight: "bold", ml: 2 }}
												>
													{option.stravaName}
												</Typography>
											</span>
											<span
												style={{
													display: "flex",
													flexDirection: "row",
													alignItems: "center",
													justifyContent: "left",
												}}
											>
												<img
													src="img/Deloitte_D_Circle.png"
													alt="Deloitte logo"
													width="16px"
													height="16px"
													style={{
														border: "2px solid #86BC25",
														borderRadius: "50%",
													}}
												/>
												<Typography
													variant="caption"
													sx={{ fontWeight: "normal", ml: 2 }}
												>
													{option.deloitteName.length > 0
														? option.deloitteName
														: "No name available"}
												</Typography>
												<Typography
													variant="caption"
													sx={{ fontWeight: "normal", ml: 2 }}
												>
													(
													{option.email.length > 0
														? option.email
														: "no email available"}
													)
												</Typography>
											</span>
										</span>
									</Box>
								)}
								renderInput={(params) => (
									<TextField
										{...params}
										label="Athlete name, email, or Strava name"
										inputProps={{
											...params.inputProps,
											autoComplete: "new-password",
										}}
									/>
								)}
								inputValue={searched}
								value={chosen}
								onInputChange={handleSearchTermChange}
								onChange={(_event, value) => {
									setChosen(value);
									displayCard(value.id);
								}}
							/>
							{loading ? (
								<Skeleton variant="rounded" height={320} ref={ref} />
							) : athlete ? (
								<AthleteProfileCard
									athlete={athlete}
									dimensions={props.dimensions}
									format={formatAmount}
									close={closeProfileCard}
								/>
							) : (
								<Card sx={{ borderStyle: "dashed" }}>
									<CardContent>
										<Typography
											variant="h6"
											textAlign="center"
											fontWeight="600"
										>
											No athlete selected.
										</Typography>
										<Typography variant="body2" textAlign="center">
											Select an athlete from the leaderboard, or search for an
											athlete by name, Deloitte email, or Strava name.
										</Typography>
									</CardContent>
								</Card>
							)}
						</CardContent>
					</Card>
				</Grid>
			</Grid>
		</Container>
	);
};

export default LeaderboardPage;
