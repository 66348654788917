import React from "react";
import PropTypes from "prop-types";
import {
	Header,
	Footer
} from "./components";
import {
	Box,
	Container,
	Grid,
	Card,
	CardContent,
	Typography,
	Chip,
	Tabs,
	Tab,
} from "@mui/material";
import RouteIcon from "@mui/icons-material/Route";
import TimerIcon from "@mui/icons-material/Timer";
import FilterHdrIcon from "@mui/icons-material/FilterHdr";
import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import EmojiEventsIcon from "@mui/icons-material/EmojiEvents";
import SpeedOutlinedIcon from "@mui/icons-material/SpeedOutlined";
import EmojiEventsOutlinedIcon from "@mui/icons-material/EmojiEventsOutlined";
import LanguageOutlinedIcon from "@mui/icons-material/LanguageOutlined";
import EqualizerOutlinedIcon from "@mui/icons-material/EqualizerOutlined";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import moment from "moment";
import GA4React from "ga-4-react";
import { ChallengesPage, LoadingPage, PersonalPage, LeaderboardPage, GlobalPage, HeatmapPage } from './pages';

var ga4react;
const colors = [
	"#9DD4CF",
	"#A0DCFF",
	"#E3E48D",
	"#007680",
	"#FFCD00",
	"#C0D0C0",
	"#DA291C",
	"#ED8B00",
	"#7BB421",
	"#4E8F97",
	"#F6F6F6",
	"#3EFAC5",
	"#53565A",
	"#92B944",
	"#01AD99",
	"#C93B2B",
	"#000000",
	"#33F0FF",
	"#F3F3F3",
];
const chartColor = {
	light: "#FFF",
	dark: "#0e0e10",
};
const lastYearColor = "#808080";
const tabLabels = [
	"Personal",
	"Challenges",
	"Leaderboards",
	"Global",
	"Heatmap",
];
const tabIcons = [
	<SpeedOutlinedIcon />,
	<EmojiEventsOutlinedIcon />,
	<EqualizerOutlinedIcon />,
	<LanguageOutlinedIcon />,
	<LocalFireDepartmentIcon />,
];

function TabPanel(props) {
	const { children, value, index, ...other } = props;
	return (
		<div
			role="tabpanel"
			hidden={value !== index}
			id={`simple-tabpanel-${index}`}
			aria-labelledby={`simple-tab-${index}`}
			{...other}
		>
			{value === index && <div>{children}</div>}
		</div>
	);
}

TabPanel.propTypes = {
	children: PropTypes.node,
	index: PropTypes.any.isRequired,
	value: PropTypes.any.isRequired,
};

function a11yProps(index) {
	return {
		id: `simple-tab-${index}`,
		"aria-controls": `simple-tabpanel-${index}`,
	};
}

class App extends React.Component {
	constructor(props) {
		super(props);
		this.state = {
			toggle: 1,
			tabSelected: -1,
			anchorElNav: 0,
			anchorElUser: 0,
			challengeList: 0,
			oSelected: -1,
			tSelected: -1,
			avatars: new Map(),
		};
		this.handleTabSelection = this.handleTabSelection.bind(this);
		this.orderColorsByType = this.orderColorsByType.bind(this);
		this.toggleSwitch = this.toggleSwitch.bind(this);
		this.buildPaceMessage = this.buildPaceMessage.bind(this);
		this.parentNotificationCompleted = this.parentNotificationCompleted.bind(this);
		this.updateChallengeStats = this.updateChallengeStats.bind(this);
		this.updateMyTeam = this.updateMyTeam.bind(this);
		this.handleChallengeList = this.handleChallengeList.bind(this);
		this.formatAmount = this.formatAmount.bind(this);
		this.showTooltip = this.showTooltip.bind(this);
		this.resetTooltip = this.resetTooltip.bind(this);
	}

	toggleSwitch(_event, newSelection) {
		if (newSelection !== null) {
			this.setState({
				toggle: newSelection,
				dimIndex: this.state.data.dimensions.findIndex(dim => dim.id === newSelection),
				oSelected: -1,
				tSelected: -1
			});
		}
	}

	componentDidMount() {
		ga4react = new GA4React(
			process.env.REACT_APP_TARGET === "www"
				? process.env.REACT_APP_GOOGLE_ANALYTICS_ID_PROD
				: process.env.REACT_APP_GOOGLE_ANALYTICS_ID_TEST
		);
		ga4react.initialize().catch(function (err) {
			window.gtag = function () {
				return undefined;
			};
			console.error(err);
		});
		let params = {
			athleteId: this.props.athleteId,
			utcOffset: new Date().getTimezoneOffset(),
			noStrava: process.env.REACT_APP_NO_STRAVA,
		};
		let today = new Date();
		let date =
			String(today.getMonth() + 1).padStart(2, "0") +
			"-" +
			String(today.getDate()).padStart(2, "0");
		if (date === "02-29") {
			date = "02-28";
		}
		let personalUrl =
			"api/getAthleteActivities?" +
			Object.keys(params)
				.map((key) =>
					typeof params[key] !== "undefined" ? key + "=" + params[key] : ""
				)
				.join("&");
		let challengesURL = ["api/getChallenges"];
		let categoriesURL = ["api/getTopAthletesByCategoryData"];
		let baseURL = "";
		switch (process.env.REACT_APP_TARGET) {
			case "qa":
				baseURL = process.env.REACT_APP_ORACLE_QA;
				break;
			case "dev":
				baseURL = process.env.REACT_APP_ORACLE_DEV;
				break;
			case "www":
				baseURL = process.env.REACT_APP_ORACLE_PROD;
				break;
			default:
				baseURL = process.env.REACT_APP_ORACLE_QA;
		};
		baseURL += "/ords/admin/DB/";
		let targetURL = [
			"getStats",
			"getTrophyRanking",
			"getStatsLastYearForPractice/" + date
		];
		let allURLs = [personalUrl].concat(
			targetURL.map((url) => baseURL + url),
			challengesURL,
			categoriesURL
		);
		Promise.all(
			allURLs.map((url) =>
				fetch(url, { accept: "application/json" }).then((resp) => resp.json())
			)
		).then((data) => {
			if (data[0].message.className === "SUCCESS") {
				let labels = Object.entries(
					data[0].yearlySummaryByDimension[
						data[0].dimensions.findIndex(
							(dim) => dim.id === data[0].mainChallengeDimensionId
						)
					]
				)
					.map((entry) => entry[0])
					.filter((label) => label !== "none");
				let tops = [];
				let totals = { Activities: data[1]["activity-count"] };
				let lastYear = { Activities: data[3]["activity-count"] };
				data[0].dimensions.forEach((dim) => {
					tops[dim.name] = data[1]["top-athletes"]
						.filter((athlete) => athlete.dimensionId === dim.id)
						.map((ath) => ({
							athlete: ath.athlete,
							id: ath.id,
							amount: ath.amount,
						}));
					totals[dim.name] = data[1].total.find(
						(dimTotal) => dimTotal.dimensionId === dim.id
					).amount;
					lastYear[dim.name] = data[3].total.find(
						(dimTotal) => dimTotal.dimensionId === dim.id
					).amount;
				});
				tops["Trophies"] = data[2].map((ath) => ({
					athlete: ath.athlete,
					id: ath.id,
					amount: ath.trophies,
				}));
				tops["Challenges"] = data[4];
				tops["Categories"] = data[5];
				let athleteCount = {
					registered: data[1]["athlete-count"],
					clubMembers: data[1]["club-member-count"],
				};
				let avatarIds = [];
				Object.keys(tops).forEach(dim => {
					switch (dim) {
						case "Challenges": 
							break;
						case "Categories":
							avatarIds.push(...tops[dim].athletes.map(ath => ath.id));
							break;
						default:
							avatarIds.push(...tops[dim].map(ath => ath.id));
							break;
					}
				});
				this.setState({
					data: data[0],
					dimIndex: data[0].dimensions.findIndex(
						(dim) => dim.id === data[0].mainChallengeDimensionId
					),
					labels: labels,
					topAthletes: tops,
					avatars: this.buildAvatarMap(avatarIds),
					topic: data[1].dimensions[0].name,
					popular: {
						Activity: data[1]["most-popular-disciplines"]["by-activity"],
						Athlete: data[1]["most-popular-disciplines"]["by-athlete"],
					},
					totals: totals,
					athleteCount: athleteCount,
					lastYear: lastYear,
					tabSelected: 0,
				});
			}
		});
	}

	buildAvatarMap(ids) {
		let avatars = new Map();
		ids.forEach(id => {
			if (!avatars.has(id)) {
				fetch("api/getAvatar/" + id, { accept: "application/json" })
					.then((resp) => resp.json())
					.then(resp => avatars.set(id, resp));
			}
		});
		return avatars;
	}

	handleTabSelection(event, newValue) {
		this.setState({ tabSelected: newValue });
		window.gtag("event", "Switched Tab", {
			event_category: "engagement",
			event_label: event.currentTarget.innerText,
		});
	}

	orderColorsByType(unsorted) {
		return unsorted.map((type) => {
			let index = this.state.labels.findIndex((s) => s === type);
			return colors[index];
		});
	}

	handleOpenNavMenu(event) {
		this.setState({
			anchorElNav: event.currentTarget,
		});
	}

	handleOpenUserMenu(event) {
		this.setState({
			anchorElUser: event.currentTarget,
		});
	}

	handleCloseNavMenu() {
		this.setState({
			anchorElNav: null,
		});
	}

	handleCloseUserMenu() {
		this.setState({
			anchorElUser: null,
		});
	}

	buildPaceMessage() {
		let {
			achievedMainGoal,
			aheadBehindYear,
			dimensions,
			mainChallengeDimensionId,
		} = this.state.data;
		let icon;
		let message;
		if (achievedMainGoal) {
			icon = <EmojiEventsIcon sx={{ color: "#F2B256!important" }} />;
			message = " Goal Achieved";
		} else {
			let paceYear = aheadBehindYear > 0 ? "ahead of" : "behind";
			let angle = aheadBehindYear > 0 ? 180 : 0;
			let positiveAheadBehindYear = Math.round(Math.abs(aheadBehindYear));
			icon = (
				<ExpandCircleDownIcon
					sx={{ mr: 1, transform: "rotate(" + angle + "deg)" }}
					fontSize="medium"
					color={aheadBehindYear > 0 ? "success" : "error"}
				/>
			);
			message =
				positiveAheadBehindYear +
				" " +
				dimensions.find((dim) => dim.id === mainChallengeDimensionId)
					.unitShort +
				". " +
				paceYear +
				" pace";
		}
		return <Chip size="small" icon={icon} label={message} />;
	}

	parentNotificationCompleted(challengeId) {
		let { data } = this.state;
		let index = data.challengeStats.findIndex(
			(ch) => ch.challengeId === challengeId
		);
		if (index > -1) {
			data.challengeStats[index].notified = true;
			this.setState({ data: data });
		}
	}

	updateChallengeStats(challengeId, challengeStat) {
		let { data } = this.state;
		let index = data.challengeSignUpStatus.findIndex(
			(ch) => ch.challenge.id === challengeId
		);
		if (index > -1) {
			data.challengeSignUpStatus[index].signedUp = true;
			data.challengeSignUpStatus[index].enrolledAthleteCount++;
			data.challengeSignUpStatus[index].updateParticipants = true;
		}
		if (typeof challengeStat.challengeName !== "undefined") {
			challengeStat.updateParticipants = true;
			data.challengeStats.push(challengeStat);
		}
		this.setState({ data: data });
	}

	updateMyTeam(newTeam) {
		let { data } = this.state;
		data.myTeam = newTeam;
		if (newTeam.id === 0) {
			data.challengeSignUpStatus.forEach((ch) => {
				if (
					ch.signedUp &&
					ch.isFuture &&
					ch.challenge.isTeam &&
					!ch.challenge.isDeloitteAttribute
				) {
					ch.signedUp = false;
					ch.enrolledAthleteCount--;
					ch.updateParticipants = false;
				}
			});
		}
		this.setState({ data: data });
	}

	handleChallengeList(__event, list) {
		if (list !== null) {
			this.setState({ challengeList: list });
		}
	}

	getHeroImage(category) {
		let picName = "././img/activity_images/" + category.split(" ")[0] + ".jpg";
		return { backgroundImage: "url(" + picName + ")" };
	}

	scrollToTop = () => {
		window.scrollTo({
			top: 0,
			behavior: "smooth",
		});
	};

	formatAmount(value, dimension) {
		let response = "";
		let { dimensions } = this.state.data;
		let unitShort = dimensions.find((dim) => dim.id === dimension).unitShort;
		if (dimension === 2) {
			const mins = parseInt((value * 60) % 60);
			response = `${parseInt(value).toLocaleString("en-US")} ${unitShort} ${mins
				.toString()
				.padStart(2, "0")} min`;
		} else {
			response = `${value.toLocaleString("en-US")} ${unitShort}`;
		}
		return response;
	}

	showTooltip(index) {
		let { data, dimIndex, labels } = this.state;
		let baseDim = data.dimensions.findIndex(dim => dim.name === 'Distance');
		let newIndex = index;
		if (dimIndex !== baseDim) {
			newIndex = Object.keys(data.yearlySummaryByDimension[dimIndex]).indexOf(labels[index]);
		}
		this.setState({ oSelected: index, tSelected: newIndex });
	}

	resetTooltip() {
		this.setState({ oSelected: -1, tSelected: -1 });
	}

	render() {
		if (this.state.data === undefined) {
			return (
				<LoadingPage/>
			);
		}
		let {
			data,
			labels,
			dimIndex,
			challengeList,
			tabSelected,
			topAthletes,
			avatars,
			topic,
			popular,
			totals,
			lastYear,
			athleteCount,
			toggle,
			oSelected,
			tSelected
		} = this.state;
		let { athleteId, isAdmin, isDarkTheme, toggleTheme } = this.props;
		let notifications = data.challengeStats.filter(
			(ch) => ch.achieved && !ch.notified
		);
		let labelsDiv = labels.map((label, i) => {
			let icon =
				"../img/activity_icons/" + label.replace(/\s/g, "").toLowerCase() + ".svg";
			return (
				<Grid item xs="auto" sx={{ mb: 1 }} key={label} className="label">
					<Chip
						size="small"
						sx={{
							backgroundColor: colors[i] + "50!important",
							border: '2px solid transparent',
							transition: "border-color 0.3s ease-in-out",
							"&:hover": {
								border: `2px solid ${colors[i]}!important`,
							},
							"&:focus": 
								oSelected === i ?
									{ border: `2px solid ${colors[i]}!important`, }
									: ""
							,
							py: ".75rem!important",
						}}
						label={
							<Typography
								variant="caption"
								sx={{ display: "block" }}
							>
								{label}
							</Typography>
						}
						icon={
							<img
								className="label-icon"
								alt={label}
								src={icon}
								style={{
									filter: isDarkTheme
										? "invert(100%) sepia(0%) saturate(7500%) hue-rotate(194deg) brightness(106%) contrast(117%)"
										: "",
								}}
							/>
						}
						onClick={() => this.showTooltip(i)}
					/>
				</Grid>
			);
		});
		let mainIndex = data.dimensions.findIndex(
			(dim) => dim.id === data.mainChallengeDimensionId
		);
		let mainChallenge = data.challengeStats.find((ch) => ch.isMain);
		let progressPercentage = Math.round(mainChallenge.progressPercentage);
		let mainChallengeAmount = mainChallenge.totalGoal;
		let daysRemaining =
			moment(mainChallenge.endDate, "MMM DD YYYY").diff(moment(), "days") + 1;
		let graphPercentage = progressPercentage > 100 ? 100 : progressPercentage;
		let dimLabels = Object.keys(data.yearlySummaryByDimension[dimIndex]);
		let icons = [<RouteIcon />, <TimerIcon />, <FilterHdrIcon />];
		let challengeToggle = ["Current", "Future"];
		let challenges = {
			Current: data.challengeStats
				.filter((ch) => !ch.isMain)
				.concat(
					data.challengeSignUpStatus.filter(
						(ch) => !ch.signedUp && !ch.isFuture
					)
				),
			Future: data.challengeSignUpStatus.filter(
				(ch) => !ch.challenge.main && ch.isFuture
			),
		};
		let highlightedCategory =
			data.mostCommonCategory === "Other Sport"
				? "Being Active"
				: data.mostCommonCategory;
		let displayActivities = data.lastActivities.map((act, i) => (
			<Card sx={{ mb: 1 }} key={i}>
				<Grid container>
					<Grid
						item
						xs={2}
						display="flex"
						alignItems="center"
						justifyContent="center"
					>
						<img
							alt={act.type}
							src={
								"../img/activity_icons/" +
								act.type.replace(/\s/g, "").toLowerCase() +
								".svg"
							}
							height="24px"
							width="24px"
							style={{
								filter: isDarkTheme
									? "invert(100%) sepia(0%) saturate(7500%) hue-rotate(194deg) brightness(106%) contrast(117%)"
									: "",
							}}
						/>
					</Grid>
					<Grid item xs={10} display="flex" flexDirection="column">
						<CardContent sx={{ pl: 0, pt: 0.5, pb: 0.5 }}>
							<Typography variant="body2" fontWeight={600} noWrap>
								{act.name}
							</Typography>
							<Grid container sx={{ mt: 0.5 }} justifyContent="space-between">
								{act.activityDimensions.map((d, j) => (
									<Grid item key={j}>
										<Typography variant="caption">
											{this.formatAmount(d.amount, d.dimensionId)}
										</Typography>
									</Grid>
								))}
							</Grid>
						</CardContent>
						<Grid container sx={{ justifyContent: "space-between" }}>
							<Grid item>
								<Typography variant="caption">
									{moment(act.date).format("MMM DD [at] hh:mm A")}
								</Typography>
							</Grid>
							<Grid item>
								<Chip
									sx={{
										borderRight: "0",
										borderBottom: "0",
										cursor: "pointer",
										ml: "auto",
										borderRadius: "4px 0 0 0 !important",
									}}
									icon={<OpenInNewIcon />}
									size="small"
									label={
										<Typography variant="caption" fontWeight={500}>
											View in{" "}
											<strong style={{ color: "#fc4c02" }}>Strava</strong>
										</Typography>
									}
									onClick={() =>
										window.open(
											"https://www.strava.com/activities/" + act.id,
											"_blank",
											"noopener,noreferrer"
										)
									}
								/>
							</Grid>
						</Grid>
					</Grid>
				</Grid>
			</Card>
		));
		return (
			<main className="table-container">
				<Header
					athleteImageSrc={data.athlete.pictureURL}
					athleteFirstName={data.athlete.firstName}
					athleteLastName={data.athlete.lastName}
					athleteId={athleteId}
					isAdmin={isAdmin}
					toggleTheme={toggleTheme}
					isDarkTheme={isDarkTheme}
					athleteOffice={data.deloitteProfile.office}
					athleteTitle={data.deloitteProfile.normalizedTitle}
					notifications={notifications}
					notificationCompleted={this.parentNotificationCompleted}
					tabSelected={tabSelected}
					highlightedCategory={highlightedCategory}
					getHeroImage={this.getHeroImage}
				/>
				<Box bgcolor="primary.tabs" id="tabs-container">
					<Container maxWidth="lg" className="container-tabs">
						<Tabs
							variant="scrollable"
							value={tabSelected}
							onChange={this.handleTabSelection}
							scrollbuttons="auto"
						>
							{tabLabels.map((tab, i) => (
								<Tab
									color="secondary"
									key={i}
									label={tab}
									icon={tabIcons[i]}
									{...a11yProps(i)}
									sx={{ minWidth: "fit-content", flex: 1 }}
								/>
							))}
						</Tabs>
					</Container>
				</Box>
				<TabPanel value={tabSelected} index={0}>
					<PersonalPage
						data={data}
						athleteId={athleteId}
						mainIndex={mainIndex}
						mainChallengeAmount={mainChallengeAmount}
						daysRemaining={daysRemaining}
						graphPercentage={graphPercentage}
						icons={icons}
						toggle={toggle}
						dimIndex={dimIndex}
						labelsDiv={labelsDiv}
						displayActivities={displayActivities}
						isDarkTheme={isDarkTheme}
						chartColor={chartColor}
						dimLabels={dimLabels}
						tSelected={tSelected}
						oSelected={oSelected}
						colors={colors}
						lastYearColor={lastYearColor}
						updateMyTeam={this.updateMyTeam}
						buildPaceMessage={this.buildPaceMessage}
						toggleSwitch={this.toggleSwitch}
						orderColorsByType={this.orderColorsByType}
						resetTooltip={this.resetTooltip}
					/>
				</TabPanel>
				<TabPanel value={tabSelected} index={1}>
					<ChallengesPage
						athleteId={athleteId}
						challenges={challenges}
						challengeList={challengeList}
						challengeToggle={challengeToggle}
						handleChallengeList={this.handleChallengeList}
						updateChallengeStats={this.updateChallengeStats}
						data={data}
					/>
				</TabPanel>
				<TabPanel value={tabSelected} index={2}>
					<LeaderboardPage
						dimensions={data.dimensions}
						topAthletes={topAthletes}
						avatars={avatars}
						topic={topic}
					/>
				</TabPanel>
				<TabPanel value={tabSelected} index={3}>
					<GlobalPage
						data={data}
						totals={totals}
						popular={popular}
						lastYear={lastYear}
						athleteCount={athleteCount}
						colors={colors}
						isDarkTheme={isDarkTheme}
						chartColor={chartColor}
					/>
				</TabPanel>
				<TabPanel value={tabSelected} index={4}>
					<HeatmapPage isDarkTheme={isDarkTheme} />
				</TabPanel>
				<Footer />
			</main>
		);
	}
}

export default App;
